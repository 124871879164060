import { CONFIG } from "core/configs/configs";
const { API_VERSION: apiVersion } = CONFIG;

const LOG_DISPUTE = `/dispute/api/${apiVersion}/dispute`;
const RESOLVE_DISPUTE = `/dispute/api/${apiVersion}/dispute/resolve`;
const REOPEN_DISPUTE = `/dispute/api/${apiVersion}/dispute/reopen`;
const GET_DISPUTE = `/dispute/api/${apiVersion}/dispute`;
const GET_PARTY_DISPUTES = `/dispute/api/${apiVersion}/dispute/party`;
const GET_DISPUTES = `/dispute/api/${apiVersion}/dispute`;
const GENERATE_DISPUTE_REPORT = `/dispute/api/${apiVersion}/dispute-report`;
const GENERATE_TEAMAPT_DISPUTE_REPORT = `/dispute/api/${apiVersion}/dispute-report/teamapt`;
const GET_DISPUTE_SETTLEMENT_INFORMATION = `/dispute/api/${apiVersion}/dispute/settlement-information`;

export const DISPUTE_ROUTES = {
  LOG_DISPUTE,
  RESOLVE_DISPUTE,
  REOPEN_DISPUTE,
  GET_PARTY_DISPUTES,
  GET_DISPUTES,
  GET_DISPUTE,
  GENERATE_DISPUTE_REPORT,
  GENERATE_TEAMAPT_DISPUTE_REPORT,
  GET_DISPUTE_SETTLEMENT_INFORMATION,
};
