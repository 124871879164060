import React, { useCallback, useEffect, useState } from "react";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { CustomModal } from "shared/components/custom-modal/custom-modal";
import { CONFIG, DEFAULT_PAGE_SIZE } from "../../../core/configs/configs";
import { HELPER } from "../../../core/helper/helper";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { CustomBreadcrumb } from "../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { PageActions } from "../../../shared/components/page-actions/page-actions";
import { PageTitle } from "../../../shared/components/page-title/page-title";
import { TransactionsFilter } from "./transactions-filter";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { Optional } from "../../../shared/components/optional/optional";
import { BACK_OFFICE_API } from "api/backofffice/index";
import { TransactionDetails } from "./transaction-details";
import { useNotification } from "core/hooks/useNotification";
import { API_SERVICE } from "api/service";
import moment from "moment";

export function Transactions(props) {
  const { addNotification } = useNotification();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalIndex, setModalIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [modalInfo] = useState({
    title: `Filter Transactions`,
    subtitle: "",
  });
  const tableHeaders = [
    { label: "Time", value: "transactionTime" },
    { label: "Masked PAN", value: "maskedPan" },
    { label: "RRN", value: "rrn" },
    { label: "Amount", value: "transactionAmount" },
    { label: "Terminal Id", value: "terminalId" },
    { label: "Response Code", value: "responseCode" },
    { label: "Actions", value: "actions" },
  ];
  const tableAuthorities = [{ label: "OPEN", value: "all" }];
  const [transactionSearchKey, setTransactionSearchKey] = useState("");
  const [selectedTransaction, setSelectedTransaction] = useState("");
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [pagination, setPagination] = useState({});
  const [error, setError] = useState(null);
  const startDateDay = CONFIG.MAX_DAYS_AGO_FOR_TRANSACTION_FILTER;
  const [initialFilter] = useState({
    from: moment().subtract(startDateDay, "days").format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
  });
  const [filterParams, setFilterParams] = useState(null);

  const getTransactions = useCallback(
    async (filter) => {
      setLoading(true);
      const params = HELPER.TO_URL_STRING({
        page: pageNo,
        size: DEFAULT_PAGE_SIZE,
        ...(props.partyId && {
          partyId: props.partyId,
          partyType: props.partyType,
        }),
        ...filter,
      });
      const url = props.url
        ? props.url
        : BACK_OFFICE_API.TRANSACTIONS.GET_TRANSACTION_RECORDS;
      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(`${url}?${params}`);
        setPagination(response?.result);
        setTransactions(response?.result?.content);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(HELPER.PROCESS_ERROR(error));
        addNotification({
          message: HELPER.PROCESS_ERROR(error),
          type: "error",
        });
      }
    },
    [pageNo, addNotification, props.url, props.partyId, props.partyType]
  );

  useEffect(() => {
    if (filterParams?.from && filterParams?.to) {
      getTransactions(filterParams);
    } else {
      getTransactions({ ...filterParams, ...initialFilter });
    }
  }, [getTransactions, initialFilter, filterParams]);

  function toggleModal(index) {
    setModalIndex(index);
    setIsModalVisible(!isModalVisible);
  }

  function closeModal() {
    setIsModalVisible(false);
  }

  function reload() {
    setPageNo(0);
    setFilterParams(null);
  }

  const handleFilter = (filter) => {
    setFilterParams(filter);
  };

  const handleViewAction = (data) => {
    setTransactionSearchKey(data?.transactionSearchKey);
    setSelectedTransaction(data);
    setCurrentIndex(1);
  };

  const handleTableAction = (data, action) => {
    switch (action) {
      case "OPEN":
        handleViewAction(data);
        return;
      default:
        break;
    }
  };

  function modalContent() {
    switch (modalIndex) {
      case 0:
        return (
          <TransactionsFilter
            partyId={props.partyId}
            partyType={props.partyType}
            partyTransactionReportGenerationUrl={
              props.partyTransactionReportGenerationUrl
            }
            closeModal={toggleModal}
            onFilter={handleFilter}
          />
        );
      default:
        break;
    }
  }

  const renderTableView = () => {
    if (loading) {
      return (
        <div className="loading-container text-center">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else {
      return (
        <CustomTable
          authorities={tableAuthorities}
          isReload={true}
          numberOfElements={pagination?.numberOfElements}
          totalPages={pagination?.totalPages}
          totalItems={pagination?.totalElements}
          currentPage={pageNo + 1}
          emptyText={"No transactions found!"}
          reload={reload}
          error={error}
          items={transactions}
          headers={tableHeaders}
          nextPage={() => {
            setPageNo(pageNo + 1);
          }}
          prevPage={() => setPageNo(pageNo - 1)}
          goToFirstPage={() => setPageNo(0)}
          goToLastPage={() => setPageNo(pagination?.totalPages - 1)}
          goToPage={(pageNo) => setPageNo(pageNo)}
          actions={["OPEN"]}
          onPerformAction={handleTableAction}
          search={true}
        />
      );
    }
  };

  const currentPageView = () => {
    switch (currentIndex) {
      case 0:
        return renderTableView();
      case 1:
        return (
          <div className="mt-2">
            <TransactionDetails
              selectedTransaction={selectedTransaction}
              transactionSearchKey={transactionSearchKey}
              goBack={() => setCurrentIndex(0)}
            />
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div>
      <>
        <CustomModal
          closeModal={closeModal}
          onHide={closeModal}
          visible={isModalVisible}
          modalContent={modalContent}
          title={modalInfo.title}
          subtitle={modalInfo.subtitle}
        />
      </>
      <PageTitle text="Transactions" />
      <CustomBreadcrumb page="Manage Transactions" />
      <PageActions>
        <Optional showIf={currentIndex === 0}>
          <AppButton
            text="Filter"
            type="button"
            buttonStyle="primary"
            icon="filter"
            onclick={() => toggleModal(0)}
          />
        </Optional>
      </PageActions>
      <div>{currentPageView()}</div>
    </div>
  );
}
