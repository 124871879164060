import { Icon } from "../../../shared/components/icons/icon";
import { TabPanel, TabView } from "primereact/tabview";
import { Optional } from "../../../shared/components/optional/optional";
import { FormattedDetails } from "../../../shared/components/formatted-details/formatted-details";
import { Accordion, AccordionTab } from "primereact/accordion";
import "./transaction-details.css";
import { useCallback, useEffect, useState } from "react";
import { BACK_OFFICE_API } from "api/backofffice/index";
import { HELPER } from "core/helper/helper";
import { AppButton } from "shared/components/app-button/app-button";
import {
  formatTransactionDetailsToBeCopied,
  getOtherInformationFields,
  getPrimaryInformationFields,
} from "./transaction-details-helpers";
import { TransactionFeeInformation } from "./fee-information";
import { API_SERVICE } from "api/service";
import { CustomModal } from "shared/components/custom-modal/custom-modal";
import { LogDispute } from "../disputes/log-dispute";
import TransactionDispute from "./transaction-dispute";
import { ErrorView } from "shared/components/error-view/error-view";
import { TransactionDisputeNotification } from "./dispute-notification";
import useDisputableCodes from "backoffice/pages/transactions/use-disputable-codes";
import DisputeDetails from "../disputes/dispute-details";

export function TransactionDetails(props) {
  const { disputableCodes } = useDisputableCodes();
  const [transactionDetails, setTransactionDetails] = useState({});
  const [error, setError] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [isDisputeModalVisible, setIsDisputeModalVisible] = useState(false);
  const [transactionDispute, setTransactionDispute] = useState(null);
  const [gettingDisputeStatus, setGettingDisputeStatus] = useState(true);
  const [isDisputable, setIsDisputable] = useState(false);
  const [currentViewIndex, setCurrentViewIndex] = useState(0);
  const [disputeStatusLogInfo, setDisputeStatusLogInfo] = useState([]);
  const { TRANSACTION_DETAILS_INDEX, DISPUTE_DETAILS_INDEX } = {
    TRANSACTION_DETAILS_INDEX: 0,
    DISPUTE_DETAILS_INDEX: 1,
  };

  const checkIfIsDisputable = useCallback(() => {
    const transactionHasDisputeCode = disputableCodes?.includes(
      transactionDetails.responseCode
    );
    setIsDisputable(transactionHasDisputeCode);
  }, [disputableCodes, transactionDetails.responseCode]);

  const checkIfDisputeIsLogged = useCallback(async () => {
    setGettingDisputeStatus(true);
    try {
      const url = BACK_OFFICE_API.DISPUTES.GET_DISPUTE;
      const response = await API_SERVICE.MAKE_GET_REQUEST(
        `${url}/${props.selectedTransaction.transactionSearchKey}`
      );
      setTransactionDispute(response);
      setGettingDisputeStatus(false);
    } catch (error) {
      if (error.status === 404) {
        setTransactionDispute(null);
      } else {
        setError(HELPER.PROCESS_ERROR(error));
      }
      setGettingDisputeStatus(false);
    }
  }, [props.selectedTransaction.transactionSearchKey]);

  const handleViewTransactionDispute = () => {
    setDisputeStatusLogInfo(JSON.parse(transactionDispute.statusLogInfo));
    setCurrentViewIndex(DISPUTE_DETAILS_INDEX);
  };

  useEffect(() => {
    setTransactionDetails(props.selectedTransaction);
    checkIfDisputeIsLogged();
    checkIfIsDisputable();
  }, [props.selectedTransaction, checkIfIsDisputable, checkIfDisputeIsLogged]);

  const reload = () => {
    checkIfIsDisputable();
  };

  const handleCopyToClipboard = () => {
    const transactionDetailsText =
      formatTransactionDetailsToBeCopied(transactionDetails);
    navigator.clipboard.writeText(transactionDetailsText).then(
      () => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 3000);
      },
      () => {
        console.error("Failed to copy");
      }
    );
  };

  const toggleDisputeModal = () => {
    setIsDisputeModalVisible(!isDisputeModalVisible);
  };

  const modalContent = () => {
    return (
      <LogDispute
        closeModal={toggleDisputeModal}
        onSuccess={checkIfDisputeIsLogged}
        transactionDetails={transactionDetails}
      />
    );
  };

  const renderView = () => {
    if (currentViewIndex === TRANSACTION_DETAILS_INDEX) {
      return (
        <>
          <div className="text-left ml-3 mt-2 pb-3">
            <span onClick={props?.goBack} className="add-cursor">
              <span>
                <Icon icon="go-back-icon" />
              </span>
            </span>
          </div>
          <TabView>
            <TabPanel header="Transaction Details">
              <Optional showIf={transactionDispute}>
                <TransactionDisputeNotification
                  resolutionStatus={transactionDispute?.resolutionStatus}
                  logCode={transactionDispute?.logCode}
                  onShowDisputeDetails={handleViewTransactionDispute}
                />
              </Optional>
              <div className="text-left">
                <Optional showIf={error}>
                  <div className="mt-5 text-center">
                    <ErrorView onReload={reload} error={error} />
                  </div>
                </Optional>
                <Optional showIf={!error}>
                  <div className="p-3 d-flex flex-column">
                    <div className="d-flex align-self-end pb-4">
                      <AppButton
                        disabled={isCopied}
                        text={
                          isCopied
                            ? "Copied to clipboard!!"
                            : "Copy to clipboard"
                        }
                        type="button"
                        buttonStyle={`bare ${isCopied ? "text-green-400" : ""}`}
                        onclick={() => handleCopyToClipboard()}
                        icon={isCopied ? "check" : "clone"}
                      />
                      <Optional
                        showIf={
                          isDisputable &&
                          !transactionDispute &&
                          !gettingDisputeStatus
                        }
                      >
                        <AppButton
                          text="Log Dispute"
                          type="button"
                          buttonStyle="primary"
                          onclick={toggleDisputeModal}
                        />
                      </Optional>
                    </div>
                    <div className="transaction-details-accordion">
                      <Accordion className="my-4" activeIndex={0}>
                        <AccordionTab
                          header={
                            <span className="text-base">
                              Primary Information
                            </span>
                          }
                        >
                          <FormattedDetails
                            details={getPrimaryInformationFields(
                              transactionDetails
                            )}
                          />
                        </AccordionTab>
                        <AccordionTab
                          header={
                            <span className="text-base">Other Information</span>
                          }
                        >
                          <FormattedDetails
                            details={getOtherInformationFields(
                              transactionDetails
                            )}
                          />
                        </AccordionTab>
                      </Accordion>
                    </div>
                  </div>
                </Optional>
              </div>
            </TabPanel>
            <TabPanel header="Fee Information">
              <TransactionFeeInformation
                transactionSearchKey={props.transactionSearchKey}
              />
            </TabPanel>
            {HELPER.HAS_AUTHORITY(
              "switch_get_one_dispute_by_transaction_search_key"
            ) ? (
              <TabPanel header="Dispute">
                <TransactionDispute
                  transactionSearchKey={props.transactionSearchKey}
                  transactionDispute={transactionDispute}
                />
              </TabPanel>
            ) : (
              <TabPanel></TabPanel>
            )}
          </TabView>
        </>
      );
    } else {
      return (
        <DisputeDetails
          goBack={() => setCurrentViewIndex(TRANSACTION_DETAILS_INDEX)}
          disputeDetails={transactionDispute}
          statusLogInfo={disputeStatusLogInfo}
        />
      );
    }
  };

  return (
    <div
      style={{ backgroundColor: "#ffffff", paddingTop: "1em" }}
      className="p-shadow-1 pb-5 mt-5"
    >
      <CustomModal
        closeModal={toggleDisputeModal}
        onHide={toggleDisputeModal}
        visible={isDisputeModalVisible}
        modalContent={modalContent}
      />
      {renderView()}
    </div>
  );
}
