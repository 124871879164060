// import { HELPER } from "../helper/helper";
import { HELPER } from "../helper/helper";

export const chargeTypeMenus = [
  {
    menu: "Charge Types",
    icon: "pi pi-home",
    index: 0,
  },
  {
    menu: "Settlement Participants",
    icon: "pi pi-home",
    index: 1,
  },
  {
    menu: "Configure Settlement",
    icon: "pi pi-home",
    index: 2,
  },
];

export const SpecialLabelCases = [
  { case: "transactionAmount", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "netSettlementAmount", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "transactionMerchantAmount", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "settlementAmount", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "transactionChargeAmount", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "transactionTotalAmount", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "amount", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "flat", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "flatFee", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "flatAmount", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "minimumCap", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "maximumCap", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "lowerBound", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "upperBound", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "capAmount", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "chargeAmount", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "minimumAmount", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "theDate", action: HELPER.FORMAT_DATE },
  { case: "transactionTime", action: HELPER.FORMAT_DATE },
  { case: "reportTime", action: HELPER.FORMAT_DATE },
  { case: "transactionDate", action: HELPER.FORMAT_DATE },
  { case: "transmissionDateTime", action: HELPER.FORMAT_DATE },
  { case: "settlementDateTime", action: HELPER.FORMAT_DATE },
  { case: "tatExpiryDate", action: HELPER.FORMAT_DATE },
  { case: "chargeAmount", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "requestAmount", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "responseAmount", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "lowerBound", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "upperBound", action: HELPER.TO_CURRENCY_FORMAT },
  { case: "requestTime", action: HELPER.FORMAT_DATE },
  { case: "responseTime", action: HELPER.FORMAT_DATE },
  { case: "logTime", action: HELPER.FORMAT_DATE },
  { case: "settlementTime", action: HELPER.FORMAT_DATE },
  { case: "responseCode", action: HELPER.RESPONSE_CODE_COLOR },
  { case: "resolutionStatus", action: HELPER.RESPONSE_STATUS },
  { case: "hasClearingEntries", action: HELPER.RESPONSE_STATUS },
  { case: "creationStatus", action: HELPER.RESPONSE_STATUS },
  { case: "disputeCreationStatus", action: HELPER.RESPONSE_STATUS },
  { case: "reportGenerationStatus", action: HELPER.RESPONSE_STATUS },
  { case: "enableBatchPostingFormatted", action: HELPER.RESPONSE_STATUS },
  { case: "isSettlementBank", action: HELPER.RESPONSE_STATUS },
  { case: "settlementBank", action: HELPER.RESPONSE_STATUS },
  { case: "status", action: HELPER.RESPONSE_STATUS },
  { case: "agent", action: HELPER.RESPONSE_STATUS },
  { case: "isDebit", action: HELPER.RESPONSE_STATUS },
  { case: "validateTerminal", action: HELPER.RESPONSE_STATUS },
  { case: "formattedStatus", action: HELPER.RESPONSE_STATUS },
  { case: "updatedAt", action: HELPER.FORMAT_DATE },
  { case: "createdOn", action: HELPER.FORMAT_DATE },
  { case: "uploadedAt", action: HELPER.FORMAT_DATE },
  { case: "active", action: HELPER.RESPONSE_STATUS },
  { case: "pending", action: HELPER.RESPONSE_STATUS },
  { case: "failed", action: HELPER.RESPONSE_STATUS },
  { case: "createdAt", action: HELPER.FORMAT_DATE },
  { case: "settlementDate", action: HELPER.FORMAT_DATE },
  { case: "completedAt", action: HELPER.FORMAT_DATE },
  { case: "createdTime", action: HELPER.FORMAT_DATE },
  { case: "completedTime", action: HELPER.FORMAT_DATE },
  { case: "creationTime", action: HELPER.FORMAT_DATE },
  { case: "percentageProcessed", action: HELPER.PERCENTAGE_STATUS },
  { case: "retentionStatus", action: HELPER.RESPONSE_STATUS },
  { case: "passwordStatus", action: HELPER.RESPONSE_STATUS },
  { case: "requestStatus", action: HELPER.RESPONSE_STATUS },
  { case: "attemptTime", action: HELPER.FORMAT_DATE },
  { case: "uploadTotalCount", action: HELPER.RESPONSE_STATUS },
  { case: "successCount", action: HELPER.RESPONSE_STATUS },
  { case: "failedCount", action: HELPER.RESPONSE_STATUS },
  { case: "transactionType", action: HELPER.RESPONSE_STATUS },
  { case: "lastModifiedOn", action: HELPER.FORMAT_DATE },
];
