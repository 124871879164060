import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useCallback, useEffect, useState } from "react";
import { FormRadioInput } from "../../../shared/components/form-component/form-radio-input";
import { API_SERVICE } from "../../../api/service";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { HELPER } from "../../../core/helper/helper";
import { AppFormDropdown } from "../../../shared/components/form-inputs/form-dropdown";
import { Optional } from "../../../shared/components/optional/optional";
import { FormattedDetails } from "../../../shared/components/formatted-details/formatted-details";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { useNotification } from "core/hooks/useNotification";
import { useThirdPartyFilter } from "../third-parties/third-party-filter-helper";
import { ThirdPartiesDropdown } from "../third-parties/third-parties-dropdown";
import { THIRD_PARTY_ENUMS } from "shared/constants";

export function ParticipantForm(props) {
  const [fees, setFees] = useState([]);
  const [filteredFees, setFilteredFees] = useState([]);
  const [toastType, setToastType] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({});
  const [parties, setParties] = useState([]);
  const [initialValues, setInitialValues] = useState({
    accountName: "",
    accountNumber: "",
    feeCode: "",
    partyType: "",
    partyTypeId: "",
    isCredit: "",
    status: "",
  });
  const validationSchemaObject = {
    accountName: Yup.string().max(
      255,
      `Too long, maximum length is 255 characters`
    ),
    feeCode: Yup.string()
      .required("Required")
      .max(15, `Too long, maximum length is 15 characters`),
    partyTypeId: Yup.string()
      .required("Required")
      .max(128, `Too long, maximum length is 128 characters`),
    partyType: Yup.string()
      .required("Required")
      .max(128, `Too long, maximum length is 128 characters`),
    accountNumber: Yup.string("Enter valid number")
      .matches(/^[0-9]+$/, "Must contain only numbers")
      .max(30, "Too long, cannot exceed 30 characters!"),
  };
  const { addNotification } = useNotification();
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [partyLoader, setPartyLoader] = useState(false);
  const [feesLoader, setFeeLoader] = useState(false);
  const FORM_VIEW_INDEX = 0;
  const FORM_DETAILS_CONFIRMATION_INDEX = 1;
  const [partyTypes] = useState([
    { name: "ISSUER", ISSUER: BACK_OFFICE_API.ISSUERS.GET_ISSUERS_LIST },
    { name: "ACQUIRER", ACQUIRER: BACK_OFFICE_API.ACQUIRER.ACQUIRER_LIST },
    {
      name: "THIRD_PARTY",
      THIRD_PARTY: BACK_OFFICE_API.THIRD_PARTY.GET_THIRD_PARTIES,
    },
    { name: "NETWORK", NETWORK: BACK_OFFICE_API.NETWORKS.GET_NETWORKS_FILLER },
  ]);
  const {
    thirdParties: filteredThirdParties,
    filtering,
    filterThirdPartiesByName,
  } = useThirdPartyFilter();
  const [thirdPartyOptions, setThirdPartyOptions] = useState([]);
  const [feeOptions, setFeeOptions] = useState([]);

  const getParties = useCallback(
    async (partyType) => {
      setPartyLoader(true);
      const formattedUrl = await partyTypes.filter(
        (item) => item[partyType.toUpperCase().replace(" ", "_")]
      )[0][partyType.toUpperCase().replace(" ", "_")];

      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(formattedUrl);
        const result = response?.result?.content
          ? response?.result?.content
          : response?.result;

        const formattedResult = result.map((item) => {
          return {
            ...item,
            name: item.financialInstitutionName
              ? item.financialInstitutionName
              : item.name,
          };
        });
        setParties(formattedResult);
        setPartyLoader(false);
      } catch (error) {
        console.log(error);
        setPartyLoader(false);
      }
    },
    [partyTypes]
  );

  useEffect(() => {
    if (filteredThirdParties?.length) {
      if (props.isUpdate) {
        setThirdPartyOptions([...filteredThirdParties, ...parties]);
      } else {
        setThirdPartyOptions(filteredThirdParties);
      }
    } else {
      setThirdPartyOptions(parties);
    }
    if (filteredFees?.length) {
      setFeeOptions(filteredFees);
    } else {
      setFeeOptions(fees);
    }
  }, [
    filteredThirdParties,
    parties,
    filterThirdPartiesByName,
    props.isUpdate,
    fees,
    filteredFees,
  ]);

  useEffect(() => {
    if (props.isUpdate) {
      const {
        accountName,
        accountNumber,
        feeCode,
        feeName,
        partyType,
        partyTypeId,
        status,
        isDebit,
      } = HELPER.changeNullValuesToEmptyStrings(props.participant);
      getParties(partyType);
      getFilteredFees(feeName);
      if (partyType === THIRD_PARTY_ENUMS.THIRD_PARTY) {
        const isByCode = true;
        filterThirdPartiesByName(partyTypeId, isByCode);
      }
      setInitialValues({
        accountName,
        accountNumber,
        feeCode,
        partyType,
        partyTypeId,
        status,
        isCredit: isDebit ? "no" : "yes",
      });
    }
  }, [props.isUpdate, getParties, props.participant, filterThirdPartiesByName]);

  const handleResponse = (isUpdate) => {
    const message = `Participant successfully ${
      isUpdate ? "updated" : "created"
    }!`;
    addNotification({
      message,
      type: "success",
    });
    const shouldReload = true;
    props.closeModal(shouldReload);
    setLoading(false);
  };

  const handleError = (error) => {
    setToastType("error");
    setMessage(HELPER.PROCESS_ERROR(error));
    setLoading(false);
  };

  const updateParticipant = async (payload) => {
    setToastType("");
    setMessage("");
    const url = `${BACK_OFFICE_API.PARTICIPANT.PARTICIPANT}/${props.participant.participantId}`;
    try {
      await API_SERVICE.MAKE_PUT_REQUEST(url, payload);
      const isUpdate = true;
      handleResponse(isUpdate);
    } catch (error) {
      handleError(error);
    }
  };

  const createParticipant = async (payload) => {
    const url = BACK_OFFICE_API.PARTICIPANT.PARTICIPANT;
    try {
      await API_SERVICE.MAKE_POST_REQUEST(url, payload);
      handleResponse();
    } catch (error) {
      handleError(error);
    }
  };

  const processConfirmationViewData = (payload, formatedParticipantName) => {
    const { status, isCredit, ...rest } = payload;
    if (props.isUpdate) {
      setDetails({
        name: formatedParticipantName,
        ...rest,
        isCredit,
        status: status ? "Active" : "Inactive",
      });
    } else {
      setDetails({
        name: formatedParticipantName,
        ...rest,
        isCredit,
      });
    }
  };

  const getParticipantName = async (payload) => {
    if (payload.partyType === THIRD_PARTY_ENUMS.THIRD_PARTY) {
      const isByCode = true;
      const response = await filterThirdPartiesByName(
        payload.partyTypeId,
        isByCode
      );
      const name = response[0]?.name ? response[0]?.name : "";
      return name;
    } else if (payload.partyType === THIRD_PARTY_ENUMS.ACQUIRER) {
      const result = parties?.filter(
        (item) => item.code === payload.partyTypeId
      )[0];
      const name = result?.financialInstitutionName
        ? result?.financialInstitutionName
        : "";
      return name;
    } else {
      const result = parties?.filter(
        (item) => item.code === payload.partyTypeId
      )[0];
      const name = result?.name ? result?.name : "";
      return name;
    }
  };
  const processSubmission = (payload, formatedParticipantName) => {
    const { active, status, isCredit, ...rest } = payload;
    setLoading(true);
    const isDebit = isCredit === "no";
    const formattedPayload = {
      ...rest,
      name: formatedParticipantName,
      feeRuleCode: props.feeRuleCode,
      isDebit,
      active: status,
    };
    if (props.isUpdate) {
      updateParticipant(formattedPayload);
    } else {
      createParticipant(formattedPayload);
    }
  };
  const submit = async (payload) => {
    const formatedParticipantName = await getParticipantName(payload);
    setToastType("");
    setMessage("");
    if (currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX) {
      processSubmission(payload, formatedParticipantName);
    } else {
      processConfirmationViewData(payload, formatedParticipantName);
      setCurrentFormIndex(FORM_DETAILS_CONFIRMATION_INDEX);
    }
  };

  const getFilteredFees = async (feeName) => {
    setFeeLoader(true);
    try {
      const params = HELPER.TO_URL_STRING({
        name: feeName,
      });
      const response = await API_SERVICE.MAKE_GET_REQUEST(
        `${BACK_OFFICE_API.FEES.FEES}?${params}`
      );
      setFilteredFees(response?.content);
    } catch (error) {
      console.log(error);
    } finally {
      setFeeLoader(false);
    }
  };

  useEffect(() => {
    const getFees = async () => {
      setFeeLoader(true);
      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(
          `${BACK_OFFICE_API.FEES.FEES}`
        );
        setFees(response?.content);
      } catch (error) {
        console.log(error);
      } finally {
        setFeeLoader(false);
      }
    };
    getFees();
  }, []);

  const handleClose = () => {
    if (currentFormIndex === FORM_VIEW_INDEX) {
      props.closeModal();
    } else {
      setCurrentFormIndex(FORM_VIEW_INDEX);
    }
  };

  const renderFormActions = () => {
    return (
      <FormActions>
        <AppButton
          type="button"
          buttonStyle="secondary"
          text={currentFormIndex === FORM_VIEW_INDEX ? "Close" : "Back"}
          onclick={handleClose}
        />
        <AppButton
          loading={loading}
          type="submit"
          buttonStyle="primary"
          text={currentFormIndex === FORM_VIEW_INDEX ? "Next" : "Submit"}
        />
      </FormActions>
    );
  };
  const renderFormAlert = () => {
    return message ? (
      <CustomToast
        title={toastType === "error" ? "Error" : "Success"}
        description={message}
        type={toastType}
        closeable={false}
        inApp={true}
      />
    ) : null;
  };
  const renderViewParticipants = () => {
    return (
      <Optional showIf={currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX}>
        <p className="modal-title p-text-left mb-1">
          Confirm participant details
        </p>
        <div className="custom-modal-item">
          <FormattedDetails details={details} />
        </div>
      </Optional>
    );
  };
  const renderFormContent = (values, errors, setFieldValue) => {
    return (
      <Optional showIf={currentFormIndex === FORM_VIEW_INDEX}>
        <p className="modal-title p-text-left ">{props.title}</p>
        <p className="modal-subtitle -mt-3">{props.subtitle}</p>
        <div className="custom-modal-item">
          <AppFormDropdown
            value={values.partyType}
            onchange={(e) => {
              setFieldValue("partyType", e?.target?.value);
              getParties(e?.target?.value);
            }}
            options={partyTypes}
            optionLabel="name"
            optionValue="name"
            placeholder="Select party type"
            label="Party Type"
            error={errors?.partyType}
            field="partyType"
            required
          />
          <Optional showIf={values.partyType === THIRD_PARTY_ENUMS.THIRD_PARTY}>
            <ThirdPartiesDropdown
              values={values}
              onFilter={filterThirdPartiesByName}
              filter={true}
              isRequired={true}
              thirdParties={thirdPartyOptions}
              filtering={filtering}
              placeholder={
                partyLoader || filtering ? "Loading..." : "Select a party"
              }
              setFieldValue={setFieldValue}
              disabled={partyLoader || filtering}
              errors={errors}
              label="Party"
              field="partyTypeId"
            />
          </Optional>
          <Optional showIf={values.partyType !== THIRD_PARTY_ENUMS.THIRD_PARTY}>
            <AppFormDropdown
              value={values.partyTypeId}
              onchange={(e) => {
                setFieldValue("partyTypeId", e?.target?.value);
              }}
              options={parties}
              optionLabel="name"
              optionValue="code"
              disabled={partyLoader}
              placeholder={partyLoader ? "Loading..." : "Select a party"}
              label="Party"
              error={errors?.partyTypeId}
              field="partyTypeId"
              required
              filter
              loading={partyLoader}
            />
          </Optional>

          <AppFormDropdown
            onFilter={(value) => {
              getFilteredFees(value.filter);
            }}
            value={values.feeCode}
            onchange={(e) => {
              setFieldValue("feeCode", e?.target?.value);
            }}
            options={feeOptions}
            optionLabel="name"
            optionValue="code"
            placeholder="Select a fee"
            label="Fee"
            error={errors?.feeCode}
            field="feeCode"
            required
            filter
            loading={feesLoader}
            disabled={feesLoader}
          />
          <div>
            <p className="date-status-style">
              Is a Credit participant? :{" "}
              <span style={{ color: "red" }}> * </span>
            </p>
            <div className="flex default p-mb-4">
              <FormRadioInput
                id="active"
                value="yes"
                name="isCredit"
                checked={values.isCredit === "yes"}
                handleChange={(e) => setFieldValue("isCredit", e.target.value)}
                label="Yes"
              />
              <FormRadioInput
                id="Inactive"
                value="no"
                name="isCredit"
                checked={values.isCredit === "no"}
                handleChange={(e) => setFieldValue("isCredit", e.target.value)}
                label="No"
              />
            </div>
          </div>
          <AppFormInput
            label="Account Name"
            name="accountName"
            type="text"
            placeholder="Input account name"
          />
          <AppFormInput
            label="Account Number"
            name="accountNumber"
            type="text"
            placeholder="Input account number"
          />
          <Optional showIf={props.isUpdate}>
            <div className="mt-3">
              <p className="date-status-style">
                Status: <span style={{ color: "red" }}> * </span>
              </p>
              <div className="flex default p-mb-4">
                <FormRadioInput
                  id="active"
                  value={true}
                  name="Status"
                  checked={values.status === true}
                  handleChange={(e) => setFieldValue("status", true)}
                  label="Active"
                />
                <FormRadioInput
                  id="Inactive"
                  value={false}
                  name="Status"
                  checked={values.status === false}
                  handleChange={(e) => setFieldValue("status", false)}
                  label="Inactive"
                />
              </div>
            </div>
          </Optional>
        </div>
      </Optional>
    );
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object(validationSchemaObject)}
        onSubmit={(values, { resetForm }) => {
          submit(values, resetForm);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            {renderFormContent(values, errors, setFieldValue)}
            {renderViewParticipants()}
            {renderFormAlert()}
            {renderFormActions(errors)}
          </Form>
        )}
      </Formik>
    </div>
  );
}
